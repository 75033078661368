import { Component, Input, OnInit } from '@angular/core';
import { AppSettings, Settings } from 'src/app/app.settings';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-header-video',
  templateUrl: './header-video.component.html',
  styleUrls: ['./header-video.component.scss']
})
export class HeaderVideoComponent implements OnInit {
  @Input('backgroundVideo') backgroundVideo; 
  @Input('contentOffsetToTop') contentOffsetToTop;
  @Input('contentMinHeight') contentMinHeight;
  @Input('title') title;
  @Input('desc') desc;
  @Input('isHomePage') isHomePage:boolean = false;
  @Input('fullscreen') fullscreen: boolean = false;
  public searchcountries:any;
  public settings: Settings;
  public isMusicOn = true;
  loopLimit = 15;
  constructor(public appSettings:AppSettings,public appService:AppService) { 
    this.settings = this.appSettings.settings;
    setTimeout(() => {
      this.settings.headerBgVideo = true;
    }); 
  }

  ngOnInit(): void {
    if(this.contentOffsetToTop){
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    } 
    var vid = <HTMLVideoElement> document.getElementById("bgVideo");
    vid.muted = true;
    this.getcountries();
  }

  ngOnDestroy(){  
    setTimeout(() => {
      this.settings.headerBgVideo = false; 
      this.settings.contentOffsetToTop = false;
    });  
  }
  getcountries()
  {
    this.appService.getcountries().subscribe(res =>{
      this.searchcountries = res;
    })
  }
  toggleMusic() {
    const videoElement = document.getElementById('bgVideo') as HTMLVideoElement;
  
    // Check if the video element is ready
    if (videoElement.readyState >= 2) {
      videoElement.muted = !this.isMusicOn;
      this.isMusicOn = !this.isMusicOn;
    } else {
      // If the video is not ready, wait for it to be loaded
      videoElement.addEventListener('canplaythrough', () => {
        videoElement.muted = !this.isMusicOn;
        this.isMusicOn = !this.isMusicOn;
      }, { once: true });
    }
  }
}
