import { Component } from '@angular/core';

@Component({
  selector: 'app-download-banner',
  templateUrl: './download-banner.component.html',
  styleUrls: ['./download-banner.component.scss']
})
export class DownloadBannerComponent {
  isVisible: boolean = true;

  closeBanner() {
    this.isVisible = false;
  }
}
