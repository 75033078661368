import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { Settings, AppSettings } from '../../app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { empty, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss'],

})
export class HeaderImageComponent implements OnInit {
  @Input('backgroundImage') backgroundImage;
  @Input('bgImageAnimate') bgImageAnimate;
  @Input('contentOffsetToTop') contentOffsetToTop;
  @Input('contentMinHeight') contentMinHeight;
  @Input('title') title;
  @Input('desc') desc;
  @Input() categorylist:string[];
  @Input('isHomePage') isHomePage: boolean = false;
  @Input('fullscreen') fullscreen: boolean = false;

  @Output() searchdataCreated = new EventEmitter<any>()

  public resForm: UntypedFormGroup;
  public name: any;
  public resname: any;
  public bgImage;
  public settings: Settings;
  public isresources: boolean = false;
  public isformsearch: boolean = false;
  myControl = new UntypedFormControl('');
  public selectedcategory;
  options: any [0] = [{'id':1, 'name':'this is name'}];
  filteredOptions: Observable<string[]>;
  constructor(public appService: AppService, public appSettings: AppSettings, private sanitizer: DomSanitizer, private router: Router, public formBuilder: UntypedFormBuilder,) {


    if (router.url == '/resources') {
      this.isresources = true;
    }
    this.settings = this.appSettings.settings;
    setTimeout(() => {
      this.settings.headerBgImage = true;
    });
  }

  ngOnInit() {  
    this.resForm = this.formBuilder.group({
      name: null,
    });

    if (this.contentOffsetToTop) {
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    }
    if (this.backgroundImage) {
      this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url(' + this.backgroundImage + ')');
    }
  }

  OnHumanSelected(option) {
    this.selectedcategory = option.value;
}

  public onresFormSubmit(values): void {
    if (this.resForm.valid) {
      if (values.name != null || values.name != '') {

        this.appService.getressearch(this.selectedcategory);

        var formdata = new FormData;
        formdata.append('address', values.name);
        formdata.append('category', this.selectedcategory);
        this.searchdataCreated.emit(formdata);
        console.warn(formdata);     
      }
    }
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.settings.headerBgImage = false;
      this.settings.contentOffsetToTop = false;
    });
  }

  ngOnChanges(val){
    this.options = this.categorylist 
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );               
}

displayFn(value?: number) {
  console.warn(value);
  return value ? this.options.find(_ => _.id === value).name : undefined;
}
  




}
