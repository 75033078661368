import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false,
  suppressScrollX: true               
};

import { AgmCoreModule } from '@agm/core'; 
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
 
import { PipesModule } from '../theme/pipes/pipes.module';
import { DirectivesModule } from '../theme/directives/directives.module'; 
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgPipesModule } from 'ngx-pipes';

import { HeaderImageComponent } from './header-image/header-image.component';
import { HeaderCarouselComponent } from './header-carousel/header-carousel.component';
import { PropertyItemComponent } from './property-item/property-item.component';
import { LoadMoreComponent } from './load-more/load-more.component';
import { PropertiesToolbarComponent } from './properties-toolbar/properties-toolbar.component';
import { PropertiesSearchComponent } from './properties-search/properties-search.component';
import { PropertiesSearchNewComponent } from "./properties-search-new/properties-search-new.component";
import { CompareOverviewComponent } from './compare-overview/compare-overview.component';
import { RatingComponent } from './rating/rating.component';
import { PropertiesSearchResultsFiltersComponent } from './properties-search-results-filters/properties-search-results-filters.component';
import { PropertiesCarouselComponent } from './properties-carousel/properties-carousel.component';
import { ClientsComponent } from './clients/clients.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { CommentsComponent } from './comments/comments.component';
import { TestimonialsComponent } from './testimonials/testimonials.component'; 
import { OurAgentsComponent } from './our-agents/our-agents.component';
import { MissionComponent } from './mission/mission.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { LogoComponent } from './logo/logo.component';
import { HeaderMapComponent } from './header-map/header-map.component';
import { HeaderVideoComponent } from './header-video/header-video.component'; 
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component'; 
import { DialogHeaderControlsComponent } from './dialog-header-controls/dialog-header-controls.component';
import { DownloadBannerComponent } from './download-banner/download-banner.component'; 

@NgModule({
  imports: [
    CommonModule,
    LazyLoadImageModule,
    RouterModule,
    ReactiveFormsModule,
    SwiperModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    AgmCoreModule,
    AgmSnazzyInfoWindowModule,
    PipesModule,
    DirectivesModule   
  ],
  exports: [
    RouterModule,
    LazyLoadImageModule,
    ReactiveFormsModule,
    NgPipesModule,
    SwiperModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    AgmCoreModule,
    AgmSnazzyInfoWindowModule,
    PipesModule,
    DirectivesModule, 
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    PropertyItemComponent,
    LoadMoreComponent,
    PropertiesToolbarComponent,
    PropertiesSearchComponent,
    PropertiesSearchNewComponent,
    CompareOverviewComponent,
    RatingComponent,
    PropertiesSearchResultsFiltersComponent,
    PropertiesCarouselComponent,
    ClientsComponent,
    GetInTouchComponent,
    CommentsComponent,
    TestimonialsComponent,   
    OurAgentsComponent, 
    MissionComponent, 
    OurServicesComponent,
    HeaderMapComponent,
    HeaderVideoComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    DownloadBannerComponent,
    DialogHeaderControlsComponent  
  ],
  declarations: [ 
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    PropertyItemComponent,
    LoadMoreComponent,
    PropertiesToolbarComponent,
    PropertiesSearchComponent,
    PropertiesSearchNewComponent,
    CompareOverviewComponent,
    RatingComponent,
    PropertiesSearchResultsFiltersComponent,
    PropertiesCarouselComponent,
    ClientsComponent,
    GetInTouchComponent,
    CommentsComponent,
    TestimonialsComponent,   
    OurAgentsComponent, 
    MissionComponent, 
    OurServicesComponent, 
    HeaderMapComponent, 
    HeaderVideoComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    DialogHeaderControlsComponent,
    DownloadBannerComponent
  ], 
  providers:[
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})
export class SharedModule { }
