import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();


  public islogin: boolean = true;
  // islogin =  new BehaviorSubject<boolean>(null);
  public currentuser:any;
  userlogin:boolean = localStorage.getItem("currentUser") === null?false:true;

  constructor(public appService:AppService) {

    
    if (localStorage.getItem('currentUser')) 
    {
      this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentuser = this.currentuser[0]; 
      this.islogin = true;
    }
    else
    this.islogin = false;


   }

  ngOnInit() {

    this.appService.userlogin.subscribe(res=>
      {
        this.userlogin = res;
      })
   }
   
  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
}