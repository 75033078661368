import { Output, EventEmitter, Component, Input, ViewChild, OnInit, SimpleChange, Directive } from '@angular/core';
import { Property } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { AppSettings, Settings } from 'src/app/app.settings';
import { LatLngLiteral, AgmMap, LatLngBounds, MapsAPILoader } from "@agm/core";
import { TestDialogComponent } from 'src/app/pages/map-search/test-dialog/test-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
declare var google: any;

// @Directive({
//     selector: 'agm-drawing-manager',
//     exportAs: 'agmDrawingManager',
//   })

@Component({
  selector: 'app-header-map',
  templateUrl: './header-map.component.html',
  styleUrls: ['./header-map.component.scss']
})
export class HeaderMapComponent implements OnInit {
  @Input('polygonpath') polygonpath:LatLngLiteral[];
  @Input('locations') locations: Array<any> = [];
  @Input('agents') agents: Array<any> = [];
  @Input('lat') lat: any;
  @Input('lng') lng: any;
  @Input('contentOffsetToTop') contentOffsetToTop; 
  @Input('fullscreen') fullscreen: boolean = false;
  
  @Output('maprecenter') maprecenter: EventEmitter<any> = new EventEmitter();
  @Output('polygoncreated') polygoncreated: EventEmitter<any> = new EventEmitter();
  @ViewChild('AgmMap') agmMap: AgmMap;

     public newlat:any;
     public newlng:any;
     public polygon:any;

  //polygonpath: LatLngLiteral[];
  private googlemap:any;
  public zoom: number = 12; 
  public markerAnimation = 'BOUNCE';  
  public property:Property;
  public singleagent:any;
  polygonmap:any;
  public settings: Settings;
  constructor(public appSettings:AppSettings, public appService:AppService, public mapsAPILoader: MapsAPILoader, public dialog: MatDialog) {

    this.settings = this.appSettings.settings; 
  }

  ngOnInit(): void {  
    if(this.contentOffsetToTop){
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
        //this.getagentsformap();
        //console.warn("this is agnet location: ",this.mapagents);
      }); 
    } 
    
  }


//   ngAfterViewInit() {
//     console.log('hello thio is : ',this.agmMap);
//     this.agmMap.mapReady.subscribe(map => {
//       const bounds: LatLngBounds = new google.maps.LatLngBounds();
//       for (const mm of this.locations) {
//         bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
//       }
//       map.fitBounds(bounds);
//     });
//   }

  mapIdle(event) {
    //console.log('idle',event);
  }


  ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
    console.warn("this is changes: ", changes);
    if(changes.locations){
      if(!changes.locations.isFirstChange()){     
        //reset map position on filter

        console.log('zoom condition: ', localStorage.getItem('zoomactive'));
        if(localStorage.getItem('zoomactive') == 'true')
        {
           this.zoom = 12;
           localStorage.setItem('zoomactive', 'false')
        }
       
        //this.lat = 40.678178;
        //this.lng = -93.944158;
        this.markerAnimation = 'BOUNCE';  
        setTimeout(() => {
          this.markerAnimation = null;
        }, 1000);
      } 
    }  
  }

  ngOnDestroy(){  
    setTimeout(() => {
      this.settings.contentOffsetToTop = false;
    });  
  }


  centermapset(e)
  {
       this.maprecenter.emit(e);
       console.warn('this is complated: ',e);
  }
  onMapReady(e:any) {

    this.googlemap = e;
    // e.setOptions({
    //   zoomControl: "true",
    //   zoomControlOptions: {
    //     position: google.maps.ControlPosition.TOP_RIGHT
    //   }
    // });


    localStorage.setItem('mapdata',JSON.stringify(e.getBounds()));
    e.addListener("dragend", this.centermapset.bind(this,e));
    e.addListener("zoom_changed", this.centermapset.bind(this,e));
    //this.initDrawingManager(e);
    
    setTimeout(() => {
      this.markerAnimation = null;
    }, 1000);
  } 

  public onMapClick(e:any){
    console.log(e); 
  }

  public onMarkerClick(e:any, propertyId:number,type?:any){ 
    this.lat = e.latitude;
    this.lng = e.longitude;  
    // if(this.zoom < 11){
    //   this.zoom = 11;
    // }
    this.property = null;
    this.singleagent =null;
    setTimeout(() => {
      if(type == 'agent')
      {
        this.appService.getagentById(propertyId).subscribe(res => {
          this.singleagent = res.result;
          console.warn('this is eagent dtails :',this.singleagent );
        });

      }
      else
      {
        const dataget=[];
        dataget[0] = propertyId;
      this.appService.getPropertyById(dataget).subscribe(res => {
        this.property = res;
      });
    }
    }, 500);  
  }

  public onZoomChange(e:any){ 
    this.zoom = e;
    console.warn(e)
  } 
  singleProperty(id:any){
    console.log(id)
    const dialogRef = this.dialog.open(TestDialogComponent,{ height: '100%',
    width: '100%',data: {id: id,campare:true}});
    // const dialogRef = this.dialog.open(PropertyComponent,{ height: '400px',
    // width: '600px',});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  initDrawingManager(map: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon","circle"]
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        geodesic:true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);


    google.maps.event.addListener(drawingManager,'polygoncomplete',function(polygon) {

      // complete functions
      //var polygonBounds = polygons[i].getPath(); polygon.getPath()

      var polygonBounds = polygon.getPath();
      var contentString = '((';


      polygonBounds.forEach(function(xy, i) {
        contentString += xy.lat() +' ' + xy.lng()+ ',';
        
      });

      contentString += '))';

      this.polygoncreated.emit(contentString);
      
      console.warn('polygon path', contentString);
      google.maps.event.addListener(polygon, 'dragend', function(){
        console.warn('dragend polygon fucniton');
      });
    
      google.maps.event.addListener(polygon.getPath(), 'set_at', function() {
        // complete functions
      });
    
      google.maps.event.addListener(polygon.getPath(), 'insert_at', function() {
        // complete functions
      });
    
    });

  }

  removepolygon($event)
  {
    console.warn('this is current map = ', this.googlemap);
    $event.setMap(null);

  
  }
  // polygonCreated($event) {
  //   console.log('coordinates_changed:',$event);


  //   if (this.polygon) {
  //     this.polygon.setMap(null);
  //   }
  //   this.polygon = $event;
  //   this.addPolygonChangeEvent(this.polygon);
  //   google.maps.event.addListener(this.polygon, 'coordinates_changed', function (index, obj) {
  //     // Polygon object: yourPolygon
  //     console.log('coordinates_changed');
  //   });

  // }

  addPolygonChangeEvent(polygon) {
    var me = polygon,
      isBeingDragged = false,
      triggerCoordinatesChanged = function () {
        // Broadcast normalized event
        google.maps.event.trigger(me, 'coordinates_changed');
      };

    // If  the overlay is being dragged, set_at gets called repeatedly,
    // so either we can debounce that or igore while dragging,
    // ignoring is more efficient
    google.maps.event.addListener(me, 'dragstart', function () {
      isBeingDragged = true;
    });

    // If the overlay is dragged
    google.maps.event.addListener(me, 'dragend', function () {
      triggerCoordinatesChanged();
      isBeingDragged = false;
    });

    // Or vertices are added to any of the possible paths, or deleted
    var paths = me.getPaths();
    paths.forEach(function (path, i) {
      google.maps.event.addListener(path, "insert_at", function () {
        triggerCoordinatesChanged();
      });
      google.maps.event.addListener(path, "set_at", function () {
        if (!isBeingDragged) {
          triggerCoordinatesChanged();
        }
      });
      google.maps.event.addListener(path, "remove_at", function () {
        triggerCoordinatesChanged();
      });
    });
  };

  getPaths() {
    console.log("get path");
    if (this.polygon) {
      const vertices = this.polygon.getPaths().getArray()[0];
      let paths = [];
      vertices.getArray().forEach(function (xy, i) {
        // console.log(xy);
        let latLng = {
          lat: xy.lat(),
          lng: xy.lng()
        };
        paths.push(JSON.stringify(latLng));
      });
      return paths;
    }
    return [];
  }
}
