import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public lat:any;
  public lng:any;
  public loginError:any;

  serverUrl = 'https://realknu.com/backend/';
  errorData: {};

  constructor(private http: HttpClient,private snackBar: MatSnackBar) { }

  redirectUrl: string;

  resetpassword(username: string)
  {
    return this.http.post<any>(`${this.serverUrl}api/Api/forgot_password`, {email_id: username})
    .pipe(catchError(this.handleError));
  }
  login(username: string, password: string) {


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          
          // alert(this.lng);
        },
        (error) => {
          console.warn('error message', error.PERMISSION_DENIED);
          if (error.code == error.PERMISSION_DENIED) {
            this.loginError = 'Please trun on your location service to show on map';
            this.snackBar.open(this.loginError, 'x', { panelClass: 'success', verticalPosition: 'bottom', duration: 6000 });
          } else {
            this.loginError='Unable to retrieve location information.';
            this.snackBar.open(this.loginError, 'x', { panelClass: 'success', verticalPosition: 'bottom', duration: 6000 });
          }
        },
        { timeout: 60000 }
      );
    }
    else{
      this.lat =  '';
      this.lng =  '';
    }
    return this.http.post<any>(`${this.serverUrl}api/Api/signinuser`, {email_id: username, password: password, txt_lat_agent:this.lat, txt_long_agent:this.lng})

    // return this.http.post<any>(`${this.serverUrl}api/Api/signinuser`, {email_id: username, password: password})
    .pipe(catchError(this.handleError));

    
    // return this.http.post<any>(`${this.serverUrl}api/Api/signinuser`, {email_id: username, password: password})
    // .pipe(map(user => {
    //    console.warn(user.result);
    //     if (user && user.result[0].token) {
    //       console.warn('intoken',user.result[0].token)
    //       localStorage.setItem('currentUser', JSON.stringify(user.result));
    //     }
    //   }),
    //   catchError(this.handleError)
    // );
  }

register(formdata)
  {
    return this.http.post<any>(`${this.serverUrl}api/Api/agent_signup`, formdata)
    .pipe(map(user => {
       //console.warn(user.result[0]);
        if (user && user.code == 1 && user.result[0].token) {
          console.warn('intoken',user.result[0].token)
          localStorage.setItem('currentUser', JSON.stringify(user.result));
        }
      }),
      catchError(this.handleError)
    );
  }
  addsubagent(formdata)
  {
    return this.http.post<any>(`${this.serverUrl}api/Api/add_sub_agent`, formdata);
  }

  isLoggedIn() 
  {
    if (localStorage.getItem('currentUser')) 
    {
      return true;
    }
    return false;
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.token;
  }

  logout() {
    localStorage.removeItem('currentUser');
   
  }

  private handleError(error) {

     console.warn('error Details: ', error);
    // if (error.error instanceof ErrorEvent) {

    //   // A client-side or network error occurred. Handle it accordingly.
    //   console.error('An error occurred:', error.error.message);
    // } else {

    //   // The backend returned an unsuccessful response code.
    //   // The response body may contain clues as to what went wrong.
    //   console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    // }

    // // return an observable with a user-facing error message
    // this.errorData = {
    //   errorTitle: 'Oops! Request for document failed',
    //   errorDesc: 'Something bad happened. Please try again later.'
    // };
    return throwError(error.message || 'server Error:');
  }
}
