import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  public currentuser:any;
  public userId:any;
  constructor(public appService:AppService,public router:Router)
  {
    
    if (localStorage.getItem('currentUser') != null) 
    {
      this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentuser = this.currentuser[0];
      this.userId = this.currentuser.user_type;
       
    }

  }

  ngOnInit() {

    
  }
  logout()
  {
    console.warn('there');
    this.router.navigate(['/login']);
    //routerLink="/login";
  }


}
