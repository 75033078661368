import { Component, OnInit, ViewChild, HostListener, ViewChildren, QueryList, Optional, Inject } from '@angular/core';
import { LatLngLiteral } from "@agm/core";
import { AppService } from "../../../app.service";
import { Settings, AppSettings } from 'src/app/app.settings';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Property, Pagination, Location } from '../../../app.models';
import * as $ from 'jquery';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { NavigationStart, Router } from '@angular/router';
import { EmbedVideoService } from 'ngx-embed-video';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { PropertyComponent } from "../../properties/property/property.component";
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TestDialogComponent } from '../test-dialog/test-dialog.component';
import { CompareOverviewComponent } from '../../../shared/compare-overview/compare-overview.component';

declare const google: any;

@Component({
  selector: 'app-compares',
  templateUrl: './compares.component.html',
  styleUrls: ['./compares.component.scss']
})
export class ComparesComponent implements OnInit {


  public InProgress: any;
  public send_data: any[];
  public slides = [];
  public properties: Property[] = [];
  public mapproperties: Property[] = [];
  public listingproperties: any[];
  public viewType: string = 'grid';
  public viewCol: number = 50;
  public count: number = 20;
  public sort: string;
  public embedVideo: any;
  public searchFields: any;
  public removedSearchField: string;
  public pagination: Pagination = new Pagination(1, 20, null, 2, 0, 0);
  public message: string;
  public featuredProperties: Property[];
  public locations: Location[] = [];
  public agents: any;
  public searchlat: any;
  public searchlng: any;
  public property: any;
  public agnetID: any;
  public contactForm: UntypedFormGroup;
  public polygonpath: LatLngLiteral[];
  public agent: any;
  public totalfound: any;
  public settings: Settings;
  public config: SwiperConfigInterface = {};
  public config2: SwiperConfigInterface = {};
  private plygontext: any;
  public islogin: boolean = false;
  public pageID: any = 1;
  public sortings = ['Sort by Default', 'Newest', 'Oldest', 'Price (Low to High)', 'Price (High to Low)'];


  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    public dialog: MatDialog,
    private router: Router,
    private embedService: EmbedVideoService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogdata: any,
    public fb: UntypedFormBuilder) {
    this.settings = this.appSettings.settings;
    localStorage.setItem('zoomactive', 'false')

    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          //alert('there');
          window.location.reload();
        }
      });

  }




  @ViewChildren(SwiperDirective) swipers: QueryList<SwiperDirective>;



  public ngOnInit() {
    if(localStorage.getItem('currentUser')){
      this.islogin=true;
    }

    this.sort = this.sortings[0];
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      phone: ['', Validators.required],
      message: ['', Validators.required],
      agentemail: [''],
    });



    this.searchlat = JSON.parse(localStorage.getItem('currnet_latitude'));
    this.searchlng = JSON.parse(localStorage.getItem('currnet_longitude'));


    setTimeout(() => {

      this.getProperties();
      //console.warn('this is map data', localStorage.getItem('mapdata'));
    }, 500);

    $("document").ready(function () {

      $('.dropdown-menu').on('click', function (e) {
        if ($(this).hasClass('dropdown-menu-form')) {
          e.stopPropagation();
        }
      });
    });

    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });



  }



  changeSorting(sorting) {
    this.sort = sorting;
    this.getProperties();
  }
  openDialog(id: any) {

    const dialogRef = this.dialog.open(TestDialogComponent, {
      height: '100%',
      width: '100%', data: { id: id, campare: false }
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  public onContactFormSubmit(values: Object) {
    if (this.contactForm.valid) {
      //alert('there');
      var formdata = new FormData;
      formdata.append('contactdetails', JSON.stringify(values));
      formdata.append('url', this.router.url);
      this.appService.submitcontact(formdata).subscribe(data => {
        console.warn(data);
      })
      console.log(values);
    }
  }

  card: any = [1, 2, 3, 4, 5, 6];

  public getProperties(map?) {



    if (this.dialogdata.propertyUnits) {
      console.warn('unitdilogdata: ', this.dialogdata.propertydata.formattedAddress);
      var formdata = new FormData;
      formdata.append('formattedAddresst', this.dialogdata.propertydata.formattedAddress);
      formdata.append('pageID', this.pageID);
      this.appService.getcampareUnits(formdata).subscribe(data => {
        console.warn('unite dataaaaaaaaaaaaaaaa', data);

        if (data.code == 0) {
          this.listingproperties = [];
          this.pagination = new Pagination(0, 9, 0, 0, 0, 0);
          return false;
        }
        this.totalfound = data.result.recordfound;
        this.listingproperties = data.result.listing;
        this.pagination = new Pagination(this.pageID, 9, this.pageID < 2 ? 1 : this.pageID - 1, this.pageID + 1, this.totalfound, data.result.totalpages);
        console.warn('listingproperties', this.listingproperties);
      })
    }
    else {
      alert(this.dialogdata.propertyUnits);
      var formdata = new FormData;


      formdata.append('sort', this.sort);
      // Curent Locationn 
      formdata.append('latitude', this.dialogdata.propertydata.location.lat);
      formdata.append('longitude', this.dialogdata.propertydata.location.lng);

      formdata.append('price', this.dialogdata.propertydata.price);
      formdata.append('propertyStatus', this.dialogdata.propertydata.propertyStatus);
      formdata.append('bathrooms', this.dialogdata.propertydata.bathrooms);
      formdata.append('bedrooms', this.dialogdata.propertydata.bedrooms);
      formdata.append('area', this.dialogdata.propertydata.area);

      formdata.append('pageID', this.pageID);


      this.appService.getcampareProperties(formdata).subscribe(data => {
        if (data.code == 0) {
          this.listingproperties = [];
          this.pagination = new Pagination(0, 9, 0, 0, 0, 0);
          return false;
        }
        this.totalfound = data.result.recordfound;
        this.listingproperties = data.result.listing;
        this.pagination = new Pagination(this.pageID, 9, this.pageID < 2 ? 1 : this.pageID - 1, this.pageID + 1, this.totalfound, data.result.totalpages);
        console.warn('listingproperties', this.listingproperties);
      })


    }

  }

  public onPageChange(e) {
    this.pagination.page = e.pageIndex + 1;
    this.pageID = e.pageIndex + 1;
    this.getProperties();
  }


  public getPropertyById(id) {


    this.appService.getPropertyById(id).subscribe(data => {
      this.property = data;
      this.agnetID = data.agent_id;
    });
  }




  public getStatusBgColor(status) {
    switch (status) {
      case 'Buy':
        return '#558B2F';
      case 'Rent':
        return '#1E88E5';
      case 'Residential':
        return '#009688';
      case 'Commercial':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      default:
        return '#01579B';
    }
  }


  public getMLsStatusBgColor(status) {
    switch (status) {
      case "1":
        return '#558B2F';
      default:
        return '#D63B2F';
    }
  }

  public getMLsStatusText(status) {
    switch (status) {
      case "1":
        return 'Active';
      case "3":
        return 'Active With Contract';
      default:
        return 'Sold';
    }
  }

  public format_date(date) {
    return new Date(date.replace(/-/g, "/"));
  }

  public addToCompare() {
    this.appService.addToCompare(this.property, CompareOverviewComponent, (this.settings.rtl) ? 'rtl' : 'ltr');
  }

  public onCompare() {
    return this.appService.Data.compareList.filter(item => item.id == this.property.id)[0];
  }

}
