import { Component, OnInit, ViewChild, HostListener, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Property } from 'src/app/app.models';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettings, Settings } from 'src/app/app.settings';
import { CompareOverviewComponent } from 'src/app/shared/compare-overview/compare-overview.component';
import { EmbedVideoService } from 'ngx-embed-video'; 
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { Router } from '@angular/router';
import { GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral} from '@agm/core';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;  

  @ViewChild('AgmMap') agmMap: AgmMap;

  @ViewChildren(SwiperDirective) swipers: QueryList<SwiperDirective>;
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation:true
  };
  public sidenavOpen:boolean = true;
  public config: SwiperConfigInterface = {}; 
  public config2: SwiperConfigInterface = {}; 
  private sub: any;
  public agnetID:any;
  public property:any; 
  public islogin: boolean = true;
  public settings: Settings;  
  public embedVideo: any;
  public currentuser:any;
  public relatedProperties: Property[];
  public featuredProperties: Property[];
  public agent:any;
  public mortgageForm: UntypedFormGroup;
  public monthlyPayment:any;
  public contactForm: UntypedFormGroup;
  public currentUrl:string="";
  public bound:LatLngBounds;
  public senderagent:boolean=false;
  public loginuserid:any;
  public targetLanguage: any;
  translatedValues: { [key: string]: string } = {};

  constructor(
              private router:Router,
              public appSettings:AppSettings, 
              public appService:AppService, 
              private activatedRoute: ActivatedRoute, 
              private embedService: EmbedVideoService,
              public fb: UntypedFormBuilder) {
    this.settings = this.appSettings.settings; 
    if (localStorage.getItem('currentUser')) 
    {
      this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentuser = this.currentuser[0];
      this.loginuserid = '/' + this.currentuser.user_id; 
      this.islogin = true;
    }
    else
    {
      this.islogin = false;
      this.loginuserid = ''; 
    }
  }

  ngOnInit() {
    this.getRelatedProperties();
    this.getFeaturedProperties();
    // this.getAgent();
    if(window.innerWidth < 960){
      this.sidenavOpen = false;
      if(this.sidenav){
        this.sidenav.close();
      } 
    };
    this.mortgageForm = this.fb.group({
      principalAmount: ['', Validators.required],
      downPayment: ['', Validators.required], 
      interestRate: ['', Validators.required],
      period: ['', Validators.required]
    });
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      phone: ['', Validators.required],
      message: ['', Validators.required],
      agentemail: [''],
    });

    this.sub = this.activatedRoute.params.subscribe(params => {   
      
      const dataget=[];
      if(params['agentid'])
      { 
        dataget[0] = params['id']
        dataget[1] = params['agentid']
        this.senderagent=true;
      }
      else
      dataget[0] = params['id']
      this.getPropertyById(dataget);
      

    });
   
  } 


  ngOnDestroy() {
    this.sub.unsubscribe();
  }  
  public translate(fieldsToTranslate: { text: string, key: string }[]) {
    console.log('language', localStorage.getItem("localsetlang"))
    this.targetLanguage = localStorage.getItem("localsetlang"); // Replace with your target language code
    if (this.targetLanguage == 'tl')
      this.targetLanguage = 'th';


    fieldsToTranslate.forEach(field => {
      this.appService.translateText(field.text, this.targetLanguage).subscribe((response) => {
        this.translatedValues[field.key] = response.data.translations[0].translatedText;
        // console.log(response.data.translations[0].translatedText);
      });
    });
  }
  @HostListener('window:resize')
  public onWindowResize():void {
    (window.innerWidth < 960) ? this.sidenavOpen = false : this.sidenavOpen = true; 
  }

  public getPropertyById(dataget){


    this.appService.getPropertyById(dataget).subscribe(data=>{
      
      this.property = data;
      this.currentUrl = 'https://realknu.com/properties/'+this.property.id +'/'+ this.loginuserid; 
      this.agnetID = data.agent_id;
      this.getAgent();
      //console.warn('this is property return data', this.property)
      if(this.property.videos.length>= 1)  
       this.embedVideo = this.embedService.embed(this.property.videos[1].link);
      setTimeout(() => { 
        this.config.observer = true;
        this.config2.observer = true; 
        this.swipers.forEach(swiper => { 
          if(swiper){
            swiper.setIndex(1);
          } 
        }); 
      });

      //console.warn('Agent details',this.agent);
      
    });
  }

  ngAfterViewInit(){



    // console.log('this agm map: ',this.agmMap);

    // this.agmMap.mapReady.subscribe(map => {


      // var boundries = map.getBounds();
      // var highestLongitude = boundries.getNorthEast().lng(); // LatLng of the north-east corner
      // var lowestLongitude = boundries.getSouthWest().lng();
      // var lowestLatitude = boundries.getSouthWest().lat();
      // var highestLatitude = boundries.getNorthEast().lat();

      // var southWest = new google.maps.LatLng(lowestLatitude, lowestLongitude);
      // var northEast = new google.maps.LatLng(highestLatitude, highestLongitude);

      // console.log('herer', map);
      //var center = new google.maps.LatLng(this.property.location.lat, this.property.location.lng);
      // map.fitBounds(bounds);
      //map.setCenter(center);
      // setTimeout(() => {
      //   var bounds = new google.maps.LatLngBounds();
      //   bounds.extend(new google.maps.LatLng(this.property?.location.lat, this.property?.location.lng));
      //   map.fitBounds(bounds);
      // }, 1000);
      
      
    // });



    this.config = {
      observer: true,
      initialSlide: 0,
      slidesPerView: 1,
      spaceBetween: 0,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      }
    };

    this.config2 = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,      
      keyboard: true,
      navigation: false,
      pagination: false, 
      grabCursor: true,       
      loop: false, 
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        200: {
          slidesPerView: 2
        },
        480: {
          slidesPerView: 3
        },
        600: {
          slidesPerView: 4
        } 
      }
    } 

  }
  

  public onOpenedChange(){ 
    this.swipers.forEach(swiper => { 
      if(swiper){
        swiper.update();
      } 
    });     
  }

  public selectImage(index:number){ 
    this.swipers.forEach(swiper => {
      if(swiper['elementRef'].nativeElement.id == 'main-carousel'){
        swiper.setIndex(index);
      }      
    }); 
  }

  public onIndexChange(index: number) {  
    this.swipers.forEach(swiper => { 
      let elem = swiper['elementRef'].nativeElement;
      if(elem.id == 'small-carousel'){
        swiper.setIndex(index);  
        for (let i = 0; i < elem.children[0].children.length; i++) {
          const element = elem.children[0].children[i]; 
          if(element.classList.contains('thumb-'+index)){
            element.classList.add('active-thumb'); 
          }
          else{
            element.classList.remove('active-thumb'); 
          }
        }
      } 
    });     
  }

  public addToCompare(){
    this.appService.addToCompare(this.property, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public onCompare(){
    return this.appService.Data.compareList.filter(item=>item.id == this.property.id)[0];
  }

  public addToFavorites(){
    this.appService.addToFavorites(this.property, (this.settings.rtl) ? 'rtl':'ltr');
  }

  public onFavorites(){
    return this.appService.Data.favorites.filter(item=>item.id == this.property.id)[0];
  }

  public getRelatedProperties(){
    this.appService.getRelatedProperties().subscribe(properties=>{
      this.relatedProperties = properties;
    })
  }

  public getFeaturedProperties(){
    this.appService.getFeaturedProperties().subscribe(properties=>{
      this.featuredProperties = properties.slice(0,3); 
    })
  } 

  public getAgent(){

    // console.warn("this is agent id : ", this.agnetID);
    //this.agnetID = 21568;
    const formdata = new FormData;
    formdata.append('agentId', this.agnetID);

    this.appService.getAgents(formdata).subscribe(res =>{

     
      if(res.code == 1)
      {
        this.agent = res.result;
        this.contactForm.get('agentemail').setValue(this.agent.email);
      }
      //console.warn('agent details',res);
      //this.agent = res.result[0];

      //console.warn(this.agent);
    });


    // var ids = [1,2,3,4,5]; //agent ids 
    // agentId = ids[Math.floor(Math.random()*ids.length)]; //random agent id
    // this.agent = this.appService.getAgents().filter(agent=> agent.id == agentId)[0]; 
  }

  public onContactFormSubmit(values:Object){
    if (this.contactForm.valid) { 

      var formdata = new FormData;
      formdata.append('contactdetails', JSON.stringify(values));
      formdata.append('url', this.router.url);
      this.appService.submitcontact(formdata).subscribe(data => {
            console.warn(data);
      })
      console.log(values);
    } 
  }

  public format_date(date)
  {
    return  new Date(date.replace(/-/g, "/"));
  }
  getSlug(name: string): string {
    return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }

  public onMortgageFormSubmit(values:Object){ 
    if (this.mortgageForm.valid) { 
      var principalAmount = values['principalAmount']
      var down = values['downPayment']
      var interest = values['interestRate']
      var term = values['period']
      this.monthlyPayment = this.calculateMortgage(principalAmount, down, interest / 100 / 12, term * 12).toFixed(2);
    }     
  }
  public calculateMortgage(principalAmount:any, downPayment:any, interestRate:any, period:any){    
    return ((principalAmount-downPayment) * interestRate) / (1 - Math.pow(1 + interestRate, -period));
  } 

  name = 'ngx sharebuttons';

}

