import { Component, Inject, PLATFORM_ID,LOCALE_ID } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
//import { warn } from 'console';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   
  public settings: Settings;
  constructor(public appSettings:AppSettings, 
              public router:Router, 
              @Inject(PLATFORM_ID) private platformId: Object,
              @Inject(LOCALE_ID) private locale: string,
              public translate: TranslateService)
              
  {
    this.settings = this.appSettings.settings;
    // Get the browser's language
    const browserLang = navigator.language.split('-')[0]; 
    
    translate.addLangs(['en','fr','es','pt','it','srp','tl']);
    if (localStorage.getItem("localsetlang") === null)
    {
      // Check if the browser's language is one of the supported languages
      if (translate.getLangs().includes(browserLang)) {
        translate.setDefaultLang(browserLang);
        translate.use(browserLang);
      } else {
        translate.setDefaultLang('en'); 
        translate.use('en');
      }
      // translate.setDefaultLang('en'); 
      // translate.use('en');
    }
    else
    {
      translate.setDefaultLang(localStorage.getItem("localsetlang")); 
      translate.use(localStorage.getItem("localsetlang"));
    }
     
  
    this.setCurrentPosition();

    
  }

//   console.log('Default Language:', this.locale);
//   this.settings = this.appSettings.settings;
//   translate.addLangs(['en','fr','ch','es','pt','it','srp','tl']);
//   if (localStorage.getItem("localsetlang") === null)
//   {
//     const defaultLang = localStorage.getItem("localsetlang") || this.getDefaultLanguage();

//     translate.setDefaultLang(defaultLang); 
//     translate.use(defaultLang);
//   }
//   else
//   {
//     translate.setDefaultLang(localStorage.getItem("localsetlang")); 
//     translate.use(localStorage.getItem("localsetlang"));
//   }
   

//   this.setCurrentPosition();

  
// }

// getDefaultLanguage(): string {
//   // Define your mapping of locale codes to default language codes here
//   const localeToDefaultLanguage: { [key: string]: string } = {
//     'en-US': 'fr',
//     'fr': 'fr',
//     // Add more mappings as needed
//   };
  
//   return localeToDefaultLanguage[this.locale] || 'en'; // Default to 'en' if no mapping is found
// }

  ngAfterViewInit(){ 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        }); 
      }            
    });    
  }


  private setCurrentPosition() 
  {
     if(navigator.geolocation) 
     {
      navigator.geolocation.getCurrentPosition((position) => {  
        if(localStorage.getItem('currnet_latitude')=='undefined')
        {
          localStorage.setItem('currnet_latitude', JSON.stringify(position.coords.latitude));
          localStorage.setItem('currnet_longitude', JSON.stringify(position.coords.longitude));
        }

        else if(!localStorage.hasOwnProperty('currnet_latitude'))
        {
          localStorage.setItem('currnet_latitude', JSON.stringify(position.coords.latitude));
          localStorage.setItem('currnet_longitude', JSON.stringify(position.coords.longitude));
        }
      },function()
      {
         if(!localStorage.hasOwnProperty('currnet_latitude'))
         {
          localStorage.setItem('currnet_latitude', '25.7824806');
          localStorage.setItem('currnet_longitude', '-80.2644778');
         }

      },{timeout:60000});
    }
    else
    {
      // x.innerHTML = "Geolocation is not supported by this browser.";
    }
    
  }

}
