import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, NgZone, HostListener } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-properties-search-new',
  templateUrl: './properties-search-new.component.html',
  styleUrls: ['./properties-search-new.component.scss']
})
export class PropertiesSearchNewComponent implements OnInit {
  @ViewChild('dropdown') dropdown: ElementRef;
  isDropdownOpen = false;

  toggleDropdown(event: Event) {
    event.stopPropagation(); // Stop click event propagation inside the dropdown
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    // Close the dropdown if the click is outside the dropdown element
    if (!this.dropdown.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
  @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
  @Input() variant: number = 1;
  @Input() vertical: boolean = false;
  @Input() searchOnBtnClick: boolean = false;
  @Input() removedSearchField: string;
  @Input() countrieslist: any;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();

  public showMore: boolean = false;
  public form: UntypedFormGroup;
  public propertyTypes: any = [];
  public propertyCategory: any = [];
  public propertyStatuses: any = [];
  public spokenlanguges: any = [];
  public countries: any = [];
  public handicap: any = [];
  public cities = [];
  public neighborhoods = [];
  public streets = [];
  public features: any = [];
  public lat: any;
  public lng: any;
  public urlactivated: any;
  public mapsearch: boolean = false;
  public location;
  public showbuyprice: boolean = true;
  public MlsStatus: any = [];
  public iscommercial: boolean = false;
  public search_values;
  public currentuser: any;
  public islogin: boolean = false;

  public buy_from: any = [{
    value: '200000',
    label: '$200,000'
  },
  {
    value: '300000',
    label: '$300,000'
  },
  {
    value: '400000',
    label: '$400,000'
  },
  {
    value: '500000',
    label: '$500,000'
  },
  {
    value: '750000',
    label: '$750,000'
  },
  {
    value: '1000000',
    label: '$1M'
  },
  {
    value: '1250000',
    label: '$1.25M'
  },
  {
    value: '1500000',
    label: '$1.5M'
  },
  {
    value: '1750000',
    label: '$1.75M'
  },
  {
    value: '2000000',
    label: '$2M'
  }];


  public buy_to: any = [{
    value: '500000',
    label: '$500,000'
  },
  {
    value: '750000',
    label: '$750,000'
  },
  {
    value: '1000000',
    label: '$1M'
  },
  {
    value: '1500000',
    label: '$1.5M'
  },
  {
    value: '2000000',
    label: '$2M'
  },
  {
    value: '2500000',
    label: '$2.5M'
  },
  {
    value: '3000000',
    label: '$3M'
  },
  {
    value: '4000000',
    label: '$4M+'
  }];

  public rent_from: any = [{
    value: '500',
    label: '$500'
  },
  {
    value: '750',
    label: '$750'
  },
  {
    value: '1000',
    label: '$1000'
  },
  {
    value: '1500',
    label: '$1500'
  },
  {
    value: '2000',
    label: '$2000'
  },
  {
    value: '3000',
    label: '$3000'
  }];

  public rent_to: any = [{
    value: '1000',
    label: '$1000'
  },
  {
    value: '2000',
    label: '$2000'
  },
  {
    value: '3000',
    label: '$3000'
  },
  {
    value: '5000',
    label: '$5000'
  },
  {
    value: '7500',
    label: '$7500'
  },
  {
    value: '10000',
    label: '$10000+'
  }];
  constructor(public appService: AppService,
    public router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentuser = this.currentuser[0];
      this.islogin = true;

    }
    else {
      this.islogin = false;
    }
    this.search_values = JSON.parse(localStorage.getItem('filtervaluessave'));
    console.warn('valuessssss', this.search_values.myChoices);

    this.form = this.fb.group({
      propertyType: null,
      propertyStatus: this.search_values?.propertyStatus ? this.search_values.propertyStatus : 1,
      propertyCategory: this.search_values?.propertyCategory ? this.search_values.propertyCategory : null,
      country: null,


      mlsStatus: 1,
      handicap: "false",
      unit: this.search_values?.unit ? this.search_values.unit : "",
      location: [JSON.parse(localStorage.getItem('location')), Validators.required],
      lat: null,
      lng: null,
      price: this.fb.group({
        from: this.search_values?.price.from ? this.search_values.price.from : "",
        to: this.search_values?.price.to ? this.search_values.price.to : ""
      }),
      myChoices: new UntypedFormArray([]),
      property_features: new UntypedFormArray([]),
      property_view: new UntypedFormArray([]),
      onlyshowagnet: new UntypedFormArray([]),
      spokenlanguge: new UntypedFormArray([]),
      pg: this.search_values?.pg ? this.search_values.pg : null,
      searchbykeyword: this.search_values?.searchbykeyword ? this.search_values.searchbykeyword : null,
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: this.fb.group({
        from: null,
        to: this.search_values?.bedrooms.to ? this.search_values.bedrooms.to : ""
      }),
      bathrooms: this.fb.group({
        from: null,
        to: this.search_values?.bathrooms.to ? this.search_values.bathrooms.to : ""
      }),
      garages: this.fb.group({
        from: null,
        to: this.search_values?.garages.to ? this.search_values.garages.to : ""
      }),
      area: this.fb.group({
        from: this.search_values?.area.from ? this.search_values.area.from : "",
        to: this.search_values?.area.to ? this.search_values.area.to : ""
      }),
      sq_m: this.fb.group({
        from: this.search_values?.sq_m.from ? this.search_values.sq_m.from : "",
        to: this.search_values?.sq_m.to ? this.search_values.sq_m.to : ""
      }),
      yearBuilt: this.fb.group({
        from: null,
        to: this.search_values?.yearBuilt.to ? this.search_values.yearBuilt.to : null
      }),
      features: this.buildFeatures()
    });


    if (this.search_values?.propertyStatus) {
      let value = this.search_values.propertyStatus;
      this.statuschange(value);
    }


    this.urlactivated = this.router.isActive('/', true)

    this.mapsearch = this.router.isActive('/mapsearch', true)


    // console.warn('this is seasrch init: ', this.urlactivated);
    if (this.vertical) {
      this.showMore = true;
    };

    if (!this.urlactivated) {
      this.propertyTypes = this.appService.getPropertyTypes();
      this.propertyStatuses = this.appService.getPropertyStatuses();
      this.propertyCategory = this.appService.getPropertyCategory();
      this.spokenlanguges = this.appService.getSpokenLanguages(true);
      this.countries = this.appService.getCountry();
      this.handicap = this.appService.getHandiCap();
      this.cities = this.appService.getCities();
      this.neighborhoods = this.appService.getNeighborhoods();
      this.streets = this.appService.getStreets();
      this.features = this.appService.getFeatures();


      this.MlsStatus = this.appService.getStatusMls();

    }

    this.onSearchChange.emit(this.form);
    
    this.placesAutocomplete();
  }

  public buildFeatures() {
    const arr = this.features.map(feature => {
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected
      });
    })
    return this.fb.array(arr);
  }

  getcountryproperties(id, name) {
    this.appService.getLatLng(name).subscribe(res => {
      this.lat = res['results'][0].geometry.location.lat;
      this.lng = res['results'][0].geometry.location.lng;
      this.location = name;
      localStorage.setItem('currnet_latitude', JSON.stringify(this.lat));
      localStorage.setItem('currnet_longitude', JSON.stringify(this.lng));
      localStorage.setItem('location', JSON.stringify(name));

    })

    localStorage.setItem('search_country_id', id);
    this.router.navigateByUrl('/properties');


  }
  ngOnChanges() {

    if (this.removedSearchField) {
      if (this.removedSearchField.indexOf(".") > -1) {
        let arr = this.removedSearchField.split(".");
        this.form.controls[arr[0]]['controls'][arr[1]].reset();
      }
      else if (this.removedSearchField.indexOf(",") > -1) {
        let arr = this.removedSearchField.split(",");
        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);
      }
      else {
        this.form.controls[this.removedSearchField].reset();
      }
    }
  }

  public reset() {
    this.form.reset({
      propertyType: null,

      propertyCategory: null,
      country: null,
      handicap: "false",
      mlsStatus: null,

      lat: null,
      lng: null,

      myChoices: new UntypedFormArray([]),
      property_features: new UntypedFormArray([]),
      property_view: new UntypedFormArray([]),
      onlyshowagnet: new UntypedFormArray([]),
      spokenlanguge: new UntypedFormArray([]),
      pg: "",
      searchbykeyword: null,

      price: {
        from: null,
        to: null
      },
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: {
        from: null,
        to: null
      },
      bathrooms: {
        from: null,
        to: null
      },
      garages: {
        from: null,
        to: null
      },
      area: {
        from: null,
        to: null
      },
      sq_m:{
        from:null,
        to:null
      },
      yearBuilt: {
        from: null,
        to: null
      },
      features: this.features
    });
  }

  public search() {
    this.onSearchClick.emit();
  }

  public onSelectCity() {
    this.form.controls['neighborhood'].setValue(null, { emitEvent: false });
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }
  public onSelectNeighborhood() {
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }

  public getAppearance() {
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel() {
    return (this.variant == 1) ? 'always' : '';
  }

  private placesAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete.nativeElement, {
        types: ["geocode"]
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          localStorage.setItem('currnet_latitude', JSON.stringify(this.lat));
          localStorage.setItem('currnet_longitude', JSON.stringify(this.lng));
          let postalCode: any;
          let for_address: any;
          for (const component of place.address_components) {
            if (component.types.includes('postal_code')) {
              postalCode = component.short_name;
              break;
            }
          }
          let firstFive = place.formatted_address.substring(0, 5);
          if (postalCode == firstFive)
            for_address = place.formatted_address.replace(/\d{5}/, '');
          else
            for_address = place.formatted_address;
          localStorage.setItem('location', JSON.stringify(for_address));
          this.form.controls['lat'].setValue(this.lat);
          this.form.controls['lng'].setValue(this.lng);
          this.form.controls['location'].setValue(for_address);
        });
      });
    });
  }


  public getAddress() {
    this.appService.getAddress(this.lat, this.lng).subscribe(response => {
      console.log(response);
      if (response['results'].length) {
        let address = response['results'][0].formatted_address;
        //this.setAddresses(response['results'][0]); 
      }
    })
  }

  public statuschange(value) {
    if (value == 1)
      this.showbuyprice = true;
    else
      this.showbuyprice = false;
  }



  checkCheckBoxvalue(event, column, val) {
    // console.warn(event.target.value);
    const formArray: UntypedFormArray = this.form.get(column) as UntypedFormArray;
    if (event.target.value == 'Commercial') {
      this.iscommercial = true;
    }
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new UntypedFormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value == event.target.value) {
          if (event.target.value == 'Commercial') {
            this.iscommercial = false;
          }
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  setvalue(event, formName) {
    let i: number = 0;

    if (formName == "buy_from") {
      this.buy_from.forEach((row: any) => {
        if (row.label == event.target.value) {
          this.form.patchValue({ price: { from: this.buy_from[i].value } });
          return;
        }
        i++;
      });
    }

    else if (formName == "rent_from") {
      this.rent_from.forEach((row: any) => {


        if (row.label == event.target.value) {
          this.form.patchValue({ price: { from: this.rent_from[i].value } });
          return;
        }

        i++;
      });

    }

    else if (formName == "rent_to") {
      this.rent_to.forEach((row: any) => {
        if (row.label == event.target.value) {
          this.form.patchValue({ price: { to: this.rent_to[i].value } });
          return;
        }
        i++;
      });
    }

    else {
      this.buy_to.forEach((row: any) => {
        if (row.label == event.target.value) {
          this.form.patchValue({ price: { to: this.buy_to[i].value } });
          return;
        }
        i++;
      });
    }
  }

}
