export class Response {
    constructor(
                public code: number,
                public message: string, 
                public result: string[],
                public allproperties: Property[]
                ){ }
} 




export class Property {
    constructor(public id: number,
                public title: string, 
                public desc: string,
                public propertyCategory: string,
                public propertyType: string,
                public propertyStatus: string,
                public country: string, 
                public city: string,
                public state: string,
                public zipCode: string,
                public neighborhood: string[],
                public street: string[],
                public location: Location,
                public formattedAddress: string,
                public features: string[],
                public featured: boolean,
                public price: string,
                public bedrooms: number,
                public bathrooms: number,
                public garages: number,
                public area: string,
                public area_mt: string,
                public yearBuilt: number,
                public handiCap: string,
                public iconurl: string,
                public ratingsCount: number,
                public ratingsValue: number,
                public additionalFeatures: AdditionalFeature[],
                public gallery: Gallery[],
                public plans: Plan[],
                public videos: Video[],
                public published: string,
                public lastUpdate: string,
                public views: number){ }
} 


export class Area {
    constructor(public id: number, 
                public value: number,
                public unit: string){ }
}

export class AdditionalFeature {
    constructor(public id: number, 
                public name: string,
                public value: string){ }
}

export class Location {
    constructor(public propertyId: number,
                public lat: number,
                public lng: number,
             
                public iconurl:string
                ){ }
}

export class Price {
    public sale: number;
    public rent: number;
}


export class Gallery {
    constructor(public id: number, 
                // public small: string,
                public medium: string
                // public big: string
                ){ }
}

export class Plan {
    constructor(public id: number, 
                public name: string,
                public desc: string,
                public area: Area,
                public rooms: number,
                public baths: number,
                public image: string){ }
}

export class Video {
    constructor(public id: number, 
                public name: string,
                public link: string){ }
}

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number,
                public nextPage: number,
                public total: number,
                public totalPages: number){ }
}

